@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}


/* hover navbar dropdown arrow */
.arrow_down {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff;
}
/* hover navbar dropdown arrow */

/* login signup sidebar bg image */
.loginSidebar {
    background-image: url("https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/login_img_c4a81e.png");
    background-position: center 85%;
    background-repeat: no-repeat;
}
/* login signup sidebar bg image */